<template>
  <overlay-scrollbars class="page page-request">
    <div class="box box_payments">
      <div class="header">
        <div class="title">New Offer Request</div>
      </div>
      <div class="content">
        <div class="block block__bankAcc">
          <div class="main">
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Bank Type</div>
                <div class="box_list" :key="bankTypesIndex">
                  <div v-for="(item, key) in bankTypes" :key="key" class="list_item">
                    <Tmblr :active="item.active" :text="item.name" @changeState="bankTypesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherBankType">
                  <div class="box_ttl">Bank type</div>
                  <input v-model="request.other_bank_type" type="text" class="inpt inpt_acc" placeholder="Enter bank type">
                </div>
                <div class="box-icon_wrap">
                  <BankTypeIcon class="box-icon" />
                </div>
              </div>
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Country</div>
                <div class="box_list" :key="countriesIndex">
                  <div v-for="(item, key) in countries" :key="key" class="list_item">
                    <Tmblr :active="item.active" :text="item.name" @changeState="countriesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherCountry">
                  <div class="box_ttl">Country</div>
                  <select class="inpt" style="width: 100%;" v-model="request.other_country">
                    <option value="null">Select country</option>
                    <option v-for="item in allCountries" :key="item.id" :value="item">{{ item.name }}</option>
                  </select>
                </div>
                <div class="box-icon_wrap">
                  <CountryIcon class="box-icon" />
                </div>
              </div>
              <div class="box--blur__wrapper position-relative">
                <div class="box box_left box_tmblers" :class="{'box--blur': !isCountrySelected}">
                  <div class="box_ttl">License Type</div>
                  <div class="box_list" :key="licenseTypesIndex">
                    <template v-if="!isCountrySelected">
                        <div class="list_item" v-for="(item, key) in licenseTypes.slice(0, 5)" :key="key">
                            <Tmblr :active="item.active" :text="item.name" :price="item.pivot && item.pivot.price ? +item.pivot.price : +item.price" @changeState="licenseTypesChangeState(item)" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="list_item" v-for="(item, key) in licenseTypes" :key="key">
                            <Tmblr :active="item.active" :text="item.name" :price="item.pivot && item.pivot.price ? +item.pivot.price : +item.price" @changeState="licenseTypesChangeState(item)" />
                        </div>
                        <div v-if="showOtherLicenseType">
                          <div class="box_ttl">Other License Type</div>
                          <input v-model="request.other_license_type" type="text" class="inpt inpt_acc" placeholder="Enter license type">
                      </div>
                    </template>
                  </div>
                  <div class="box-icon_wrap">
                    <LicenseTypeIcon class="box-icon" />
                  </div>
                </div> 
                <div class="box_overlay--blur" v-if="!isCountrySelected">
                  <h4>Select a country to see available licenses.</h4>
                </div>
              </div>           
            </div>
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Software Type</div>
                <div class="box_list row-list" :key="softwareTypesIndex">
                  <div v-for="(item, key) in softwareTypes" :key="key" class="list_item">
                    <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="softwareTypesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherSoftwareType">
                  <div class="box_ttl">Software type</div>
                  <input v-model="request.other_software_type" type="text" class="inpt inpt_acc" placeholder="Enter software type">
                </div>
                <div class="box-icon_wrap software">
                  <SoftwareTypeIcon class="box-icon" />
                </div>
              </div>
            </div>
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Account Type</div>
                <div class="box_list" :key="accountTypesIndex">
                  <div v-for="(item, key) in accountTypes" :key="key" class="list_item">
                    <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="accountTypesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherAccountType">
                  <div class="box_ttl">Account type</div>
                  <input v-model="request.other_account_type" type="text" class="inpt inpt_acc" placeholder="Enter account type">
                </div>
                <div class="box-icon_wrap">
                  <AccountTypeIcon class="box-icon" />
                </div>
              </div>
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Membership Type</div>
                <div class="box_list" :key="membershipTypesIndex">
                  <div v-for="(item, key) in membershipTypes" :key="key" class="list_item">
                    <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="membershipTypesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherMembershipType">
                  <div class="box_ttl">Membership type</div>
                  <input v-model="request.other_membership_type" type="text" class="inpt inpt_acc" placeholder="Enter membership type">
                </div>
                <div class="box-icon_wrap">
                  <MembershipTypeIcon class="box-icon" />
                </div>
              </div>
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Operetional Type</div>
                <div class="box_list" :key="operetionalTypesIndex">
                  <div v-for="(item, key) in operetionalTypes" :key="key" class="list_item">
                    <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="operetionalTypesChangeState(item)" />
                  </div>
                </div>
                <div v-if="showOtherOperetionalType">
                  <div class="box_ttl">Operetional type</div>
                  <input v-model="request.other_operetional_type" type="text" class="inpt inpt_acc" placeholder="Enter operetional type">
                </div>
                <div class="box-icon_wrap">
                  <OperetionalTypeIcon class="box-icon" />
                </div>
              </div>
            </div>
            <div class="box-group">
              <div class="box box_left box_tmblers">
                <div class="box_ttl">Other services</div>
                <div class="row">
                  <div class="col-other-services">
                    <input 
                      class="inpt flexible"
                      type="text" 
                      name="other_service" 
                      v-model="otherServiceName" 
                      @keyup.enter="crateOtherService" 
                    />
                    <button 
                      type="button" 
                      class="btn btn_submit" 
                      @click.prvent="crateOtherService"
                    >
                      Add
                    </button>
                  </div>
                  <div class="col-other-services">
                    <select 
                      class="inpt" 
                      name="other_service_select" 
                      v-model="otherServicesSelect"
                      @change="handleSelectChange"
                    >
                      <option 
                        :value="null" 
                        selected
                      >
                        Select other service
                      </option>
                      <option 
                        v-for="option in otherServicesList"
                        :key="option.id" 
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="box_list row-list flex-wrap" :key="otherServicesIndex">
                  <div v-for="(item, key) in otherServices" :key="key" class="list_item other-services-tmblr-col">
                    <Tmblr :active="item.active" :text="item.name" :price="item.price ? +item.price : 0" @changeState="otherServicesChangeState(item)" />
                  </div>
                </div>
                <!--<div class="box-icon_wrap software">
                  <SoftwareTypeIcon class="box-icon" />
                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="d-flex w-100 total-price__wrap">
            <h5 class="total-price__title">
              Request Total Price<sup>*</sup>
            </h5>
            <h5 class="total-price__value" v-if="request.price">
              {{ totalPrice | numberFormat }}€
            </h5>
          </div>
          <div class="d-flex w-100 total-price__wrap">
            <small>
              Price is not final; it may change after contact. 
            </small>
          </div>
          <div @click="submit" class="btn btn_submit">Submit data</div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Tmblr from '@/components/elem/Tmblr.vue'
import Vue from 'vue';
import BankTypeIcon from '@/components/img/BankTypeIcon';
import SoftwareTypeIcon from '@/components/img/SoftwareTypeIcon';
import LicenseTypeIcon from '@/components/img/LicenseTypeIcon';
import OperetionalTypeIcon from '@/components/img/OperetionalTypeIcon';
import MembershipTypeIcon from '@/components/img/MembershipTypeIcon';
import AccountTypeIcon from '@/components/img/AccountTypeIcon';
import CountryIcon from '@/components/img/CountryIcon';

export default {
  components: {
    Tmblr,
    BankTypeIcon,
    SoftwareTypeIcon,
    LicenseTypeIcon,
    OperetionalTypeIcon,
    MembershipTypeIcon,
    AccountTypeIcon,
    CountryIcon,
  },
  data: () => ({
    userBankTypes: [],
    userLicenseTypes: [],
    userAccountTypes: [],
    userMembershipTypes: [],
    userOperetionalTypes: [],
    userSoftwareTypes: [],
    userCountries: [],

    bankTypes: [],
    bankTypesIndex: 0,
    licenseTypes: [],
    licenseTypesIndex: 0,
    allLicenseTypes: [],
    allLicenseTypesIndex: 0,
    membershipTypes: [],
    membershipTypesIndex: 0,
    operetionalTypes: [],
    operetionalTypesIndex: 0,
    accountTypes: [],
    accountTypesIndex: 0,
    softwareTypes: [],
    softwareTypesIndex: 0,
    countries: [],
    countriesIndex: 0,
    allCountries: [],
    allCountriesIndex: 0,
    otherServices: [],
    allOtherServices: [],
    otherServicesList: [],
    otherServicesSelect: null,
    otherServicesIndex: 0,
    otherServiceName: '',

    showOtherAccountType: false,
    showOtherBankType: false,
    showOtherSoftwareType: false,
    showOtherMembershipType: false,
    showOtherLicenseType: false,
    showOtherOperetionalType: false,
    showOtherCountry: false,
    isCountrySelected: false,

    totalPrice: 0,

    request: {
      other_bank_type: '',
      other_license_type: '',
      other_membership_type: '',
      other_operetional_type: '',
      other_account_type: '',
      other_software_type: '',
      other_country: '',
    },
    errors: {
      other_bank_type: '',
      other_license_type: '',
      other_membership_type: '',
      other_operetional_type: '',
      other_software_type: '',
      other_account_type: '',
    }
  }),
  created () {
    this.isCountrySelected = this.getIsCountrySelected();
    this.getBankTypes()
    this.getAccountTypes()
    this.getSoftwareTypes()
    this.getMembershipTypes()
    this.getOperetionalTypes()
    this.getCountries()
    this.getLicenseTypes()
    this.getOtherServices()
  },
  methods: {
    clearErrors () {
      for (let key in this.errors) {
        this.errors[key] = ''
      }
    },
    getFormattedPriceArray(array) {
      let totalArrayPrice = 0;
      
      const formattedArray = array.reduce(function(acc, el) {
        const price = el.pivot && el.pivot.price !== undefined ? +el.pivot.price : +el.price;
        
        acc[el.id] = { price: +price };
        totalArrayPrice += +acc[el.id].price;

        return acc;
      }, {});

      return { formattedArray, totalArrayPrice };
    },
    submit () {
      this.clearErrors()

      const that = this

      this.clearErrors();

      const userBankTypeIds = this.bankTypes.filter(el => el.active).map(el => el.id);
      let userCountriesIds = this.countries.filter(el => el.active && el.id !== null).map(el => el.id);

      if(this.request.other_country && this.request.other_country.id){
        userCountriesIds.push(this.request.other_country.id)
      }

      const getPriceData = (array) => {
        const { formattedArray, totalArrayPrice } = this.getFormattedPriceArray(array);

        return { ids: array, price: totalArrayPrice, formattedArray };
      };

      const dataMap = {
        licenseTypes: getPriceData(this.licenseTypes.filter(el => el.active)),
        softwareTypes: getPriceData(this.softwareTypes.filter(el => el.active)),
        accountTypes: getPriceData(this.accountTypes.filter(el => el.active)),
        membershipTypes: getPriceData(this.membershipTypes.filter(el => el.active)),
        operetionalTypes: getPriceData(this.operetionalTypes.filter(el => el.active)),
        otherServices: getPriceData(this.otherServices.filter(el => el.active)),
      };

      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'license-offers', {
      bank_types: userBankTypeIds,
      license_types: dataMap.licenseTypes.formattedArray,
      software_types: dataMap.softwareTypes.formattedArray,
      account_types: dataMap.accountTypes.formattedArray,
      membership_types: dataMap.membershipTypes.formattedArray,
      operetional_types: dataMap.operetionalTypes.formattedArray,
      other_services: dataMap.otherServices.formattedArray,
      license_price: dataMap.licenseTypes.price,
      software_price: dataMap.softwareTypes.price,
      other_price: dataMap.otherServices.price,
      account_price: dataMap.accountTypes.price,
      membership_price: dataMap.membershipTypes.price,
      operetional_price: dataMap.operetionalTypes.price,
      price: this.totalPrice,
      countries: userCountriesIds,
      other_bank_type: this.request.other_bank_type,
      other_account_type: this.request.other_account_type,
      other_software_type: this.request.other_software_type,
      other_license_type: this.request.other_license_type,
      other_membership_type: this.request.other_membership_type,
      other_operetional_type: this.request.other_operetional_type,
      }).then(function (response) {
        if (response.data.error) {
          for (let serverError in response.data.errors) {
            for (let cilentError in that.errors) {
              if (serverError === cilentError && response.data.errors[serverError][0]) {
                that.errors[cilentError] = response.data.errors[serverError][0]  
              }
            }
          }
          return
        }
        that.$noty.success("Your request data was successfully changed")

        that.$router.push({ name: 'YouOffers' })
      }).catch(function (error) {
        console.log(error);
        if (error) {
          for (let serverError in error.errors) {
            for (let cilentError in that.errors) {
              if (serverError === cilentError && response.data.errors[serverError][0]) {
                that.errors[cilentError] = response.data.errors[serverError][0]  
              }
            }
          }
          return
        }

        that.$noty.error("Your request data is not correct")
      })
    },
    crateOtherService(){
      if(this.otherServiceName){
        const that = this

        axios.post(process.env.VUE_APP_BACKEND_API_URL + 'license-offers/other-service', {
          name: this.otherServiceName
        })
        .then(function (response) {
          if(response.data.success){
            let otherService = response.data.otherService

            otherService.active = true
            
            that.otherServices.push(otherService)

            that.otherServicesIndex++

            that.otherServiceName = ''

            that.getOtherServices()
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    bankTypesChangeState (item) {
      const index = this.bankTypes.findIndex(bankType => {
          return (item.id === bankType.id)
      })

      this.unActiveItem(this.bankTypes, index, 'showOtherBankType')

      Vue.set(this.bankTypes[index], 'active', !item.active)
      if (this.bankTypes[index].name == 'Other') {
        if (this.bankTypes[index].active) {
          this.showOtherBankType = true
        } else {
          this.showOtherBankType = false
          this.request.other_bank_type = ''
        }
      }
      
      this.bankTypesIndex++
    },
    licenseTypesChangeState (item) {
      const index = this.licenseTypes.findIndex(licenseType => {
          return (item.id === licenseType.id)
      })

      this.unActiveItem(this.licenseTypes, index, 'showOtherLicenseType')
      
      Vue.set(this.licenseTypes[index], 'active', !item.active)
      
      if (this.licenseTypes[index].name == 'Other') {
        if (this.licenseTypes[index].active) {
          this.showOtherLicenseType = true
        } else {
          this.showOtherLicenseType = false
          this.request.other_license_type = ''
        }
      }
      
      this.calculateTotalPrice();
     
      this.licenseTypesIndex++
    },
    softwareTypesChangeState (item) {
      const index = this.softwareTypes.findIndex(softwareType => {
          return (item.id === softwareType.id)
      })

      this.unActiveItem(this.softwareTypes, index, 'showOtherSoftwareType')

      Vue.set(this.softwareTypes[index], 'active', !item.active)
      if (this.softwareTypes[index].name == 'Other') {
        if (this.softwareTypes[index].active) {
          this.showOtherSoftwareType = true
        } else {
          this.showOtherSoftwareType = false
          this.request.other_software_type = ''
        }
      }

      this.calculateTotalPrice();
      
      this.softwareTypesIndex++
    },
    accountTypesChangeState (item) {
      const index = this.accountTypes.findIndex(accountType => {
          return (item.id === accountType.id)
      })

      Vue.set(this.accountTypes[index], 'active', !item.active)
      if (this.accountTypes[index].name == 'Other') {
        if (this.accountTypes[index].active) {
          this.showOtherAccountType = true
        } else {
          this.showOtherAccountType = false
          this.request.other_account_type = ''
        }
      }

      this.calculateTotalPrice();
      
      this.accountTypesIndex++
    },
    membershipTypesChangeState (item) {
      const index = this.membershipTypes.findIndex(membershipType => {
          return (item.id === membershipType.id)
      })

      Vue.set(this.membershipTypes[index], 'active', !item.active)
      if (this.membershipTypes[index].name == 'Other') {
        if (this.membershipTypes[index].active) {
          this.showOtherMembershipType = true
        } else {
          this.showOtherMembershipType = false
          this.request.other_membership_type = ''
        }
      }

      this.calculateTotalPrice();
      
      this.membershipTypesIndex++
    },
    operetionalTypesChangeState (item) {
      const index = this.operetionalTypes.findIndex(operetionalType => {
          return (item.id === operetionalType.id)
      })

      Vue.set(this.operetionalTypes[index], 'active', !item.active)
      if (this.operetionalTypes[index].name == 'Other') {
        if (this.operetionalTypes[index].active) {
          this.showOtherOperetionalType = true
        } else {
          this.showOtherOperetionalType = false
          this.request.other_operetional_type = ''
        }
      }

      this.calculateTotalPrice();
      
      this.operetionalTypesIndex++
    },
    calculateTotalPrice(){
      const priceParts = ['licenseTypes', 'softwareTypes', 'accountTypes', 'membershipTypes', 'operetionalTypes', 'otherServices'];

      this.totalPrice = 0;

      priceParts.forEach(property => {
        this[property].forEach((item, i) => {
          if(item.active){
            this.totalPrice += item.pivot && item.pivot.price ? +item.pivot.price : +item.price ;
          }
        });
      });
    },
    countriesChangeState (item) {
      const index = this.countries.findIndex(country => {
        return (item.id === country.id)
      })

      this.unActiveItem(this.countries, index, 'showOtherCountry')
      
      Vue.set(this.countries[index], 'active', !item.active)
      if (this.countries[index].name == 'Other') {
        if (this.countries[index].active) {
          this.showOtherCountry = true
        } else {
          this.showOtherCountry = false
          this.request.other_country = ''
        }
      }
      
      this.countriesIndex++

      this.getLicenseTypesByCountry(this.countries[index]);
      
      this.isCountrySelected = this.getIsCountrySelected();
    },
    getLicenseTypesByCountry(country){
      if(country && country.license_types && country.license_types.length){
        Vue.set(this, 'licenseTypes', country.license_types);
      }
      else{
        Vue.set(this, 'licenseTypes', this.allLicenseTypes);
      }
      
      for (let i = 0; i < this.licenseTypes.length; i++) {
        Vue.set(this.licenseTypes[i], 'active', false);
      }
    },
    unActiveItem(array, selectedIndex, property){
      array.forEach((item, i) => {
        if(i !== selectedIndex){
          item.active = false;
          this[property] = false;

          if(property === 'showOtherCountry'){
            this.request.other_country = ''
          }
        }
      });
    },
    getIsCountrySelected() {
      if (this.countries.length) {
        for (let i = 0; i < this.countries.length; i++) {
          const item = this.countries[i];

          if (item.active) {
            return true;
          }
        }
      }
      
      return false;
    },
    getBankTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/bank-types')
        .then(function (response) {
          that.bankTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getLicenseTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/license-types')
        .then(function (response) {
          that.licenseTypes = response.data
          that.allLicenseTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    }, 
    getOtherServices() {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/other-services')
        .then(function (response) {
          that.allOtherServices = response.data
          that.otherServicesList = that.filterOtherServices(response.data)
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    filterOtherServices(items){
      let filtered = items.filter(item => {
        return !this.otherServices.some(selectedItem => selectedItem.id == item.id)
      })

      return filtered
    },
    handleSelectChange(){
      if(this.otherServicesSelect){
        let item = this.otherServicesSelect

        item.active = true

        this.otherServices.push(item)

        this.otherServicesIndex++

        this.otherServicesSelect = null

        this.getOtherServices()
      }
    },
    otherServicesChangeState(item){
      item.active = !item.active

      this.otherServicesIndex++

      this.getOtherServices()
    },
    getSoftwareTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/software-types')
        .then(function (response) {
          that.softwareTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    }, 
    getCountries () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/license-countries')
        .then(function (response) {
          that.countries = response.data
          that.countries.push({name: 'Other', code: 'ot', id: null})
          that.getAllCountries();
        })
        .catch(function (error) {
          console.log(error);
        })
    }, 
    getAllCountries () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/countries')
        .then(function (response) {
          that.allCountries = response.data.filter(item => {
            return !that.countries.some(country => country.id === item.id)
          })
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getAccountTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/account-types')
        .then(function (response) {
          that.accountTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getMembershipTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/membership-types')
        .then(function (response) {
          that.membershipTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getOperetionalTypes () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/operetional-types')
        .then(function (response) {
          that.operetionalTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
  },
  computed: {
    isAllBankTypesFetched() {
      if (this.bankTypes.length > 0 && this.userBankTypes.length >0) {
        return true
      }

      return false
    },
    isAllSoftwareTypesFetched() {
      if (this.softwareTypes.length > 0 && this.userSoftwareTypes.length >0) {
        return true
      }

      return false
    },
    isAllLicenseTypesFetched() {
      if (this.licenseTypes.length > 0 && this.userLicenseTypes.length > 0) {
        return true
      }
      
      return false
    },
    isAllAccountTypesFetched() {
      if (this.accountTypes.length > 0 && this.userAccountTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllMembershipTypesFetched() {
      if (this.membershipTypes.length > 0 && this.userMembershipTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllOperetionalTypesFetched() {
      if (this.operetionalTypes.length > 0 && this.userOperetionalTypes.length >0) {
        return true
      }
      
      return false
    },
    isAllCountriesFetched() {
      if (this.countries.length > 0 && this.userCountries.length >0) {
        return true
      }
      
      return false
    },
  },
  watch: {
    isAllBankTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.bankTypes.length; i++) {
          this.bankTypes[i].active = false
          for (let z = 0; z < this.userBankTypes.length; z++) {
            if (this.bankTypes[i].id === this.userBankTypes[z].id) {
              this.bankTypes[i].active = true
              if (this.bankTypes[i].name == 'Other') {
                this.showOtherBankType = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllSoftwareTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.softwareTypes.length; i++) {
          this.softwareTypes[i].active = false
          for (let z = 0; z < this.userSoftwareTypes.length; z++) {
            if (this.softwareTypes[i].id === this.userSoftwareTypes[z].id) {
              this.softwareTypes[i].active = true
              if (this.softwareTypes[i].name == 'Other') {
                this.showOtherSoftwareType = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllLicenseTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.licenseTypes.length; i++) {
          this.licenseTypes[i].active = false
          for (let z = 0; z < this.userLicenseTypes.length; z++) {
            if (this.licenseTypes[i].id === this.userLicenseTypes[z].id) {
              this.licenseTypes[i].active = true
              if (this.licenseTypes[i].name == 'Other') {
                this.showOtherLicenseType = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllAccountTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.accountTypes.length; i++) {
          this.accountTypes[i].active = false
          for (let z = 0; z < this.userAccountTypes.length; z++) {
            if (this.accountTypes[i].id === this.userAccountTypes[z].id) {
              this.accountTypes[i].active = true
              if (this.accountTypes[i].name == 'Other') {
                this.showOtherAccountType = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllMembershipTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.membershipTypes.length; i++) {
          this.membershipTypes[i].active = false
          for (let z = 0; z < this.userMembershipTypes.length; z++) {
            if (this.membershipTypes[i].id === this.userMembershipTypes[z].id) {
              this.membershipTypes[i].active = true
              if (this.membershipTypes[i].name == 'Other') {
                this.showOtherMembershipType = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllOperetionalTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.operetionalTypes.length; i++) {
          this.operetionalTypes[i].active = false
          for (let z = 0; z < this.userOperetionalTypes.length; z++) {
            if (this.operetionalTypes[i].id === this.userOperetionalTypes[z].id) {
              this.operetionalTypes[i].active = true
              if (this.operetionalTypes[i].name == 'Other') {
                this.showOtherOperetionalType = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllCountriesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.countries.length; i++) {
          this.countries[i].active = false
          for (let z = 0; z < this.userCountries.length; z++) {
            if (this.countries[i].id === this.userCountries[z].id) {
              this.countries[i].active = true
            }
          }
        }
      },
      deep: true
    },
    'request.other_country': {
      handler: function(newVal, oldVal) {
        if(newVal != oldVal){
          this.getLicenseTypesByCountry(newVal);
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-request {
  .title{
    padding: 0 !important;
    &:before{
      content: none !important;
    }
  }
  .box {
    margin: 0;
    position: relative;
    &_list{
      z-index: 9;
    }
    &-icon{
      width: 80%;
      height: 80%;
      display: block;
      margin-left: auto;
      margin-top: auto;
      &_wrap{
        position: absolute;
        top: 24px;
        right: 24px;
        left: 24px;
        bottom: 24px;
        transform: rotate(15deg) scale(.95);
        transform-origin: center center;
        opacity: .2;
        &.software{
          right: unset;
          margin-left: 96px;
          transform: rotate(15deg) scale(2.2);
        }
      }
    }
    &-group{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      align-items: stretch;
    }
    &_payments {
      background: transparent;
      box-shadow: none;
      .title{
        color: #09090B;
        &:before{
          background: #1763fb;
        }
      }

      .content {
        padding-top: 0;
        color: #09090B;

        .block {
          padding: 0 !important;
          padding-bottom: 1rem !important;

          .ttl {
            text-align: center;
            color: #09090B;

            svg.img {
              width: 2.5rem;
              height: 2.5rem;
            }
          }

          .main {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
            align-items: stretch;

            &_center {
              width: 45%;
            }

            .box {
              &_list.row-list{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                &.flex-wrap{
                  flex-wrap: wrap;
                }
                .other-services-tmblr-col{
                  width: 25%;
                  flex: 0 0 25%;
                  max-width: 25%;
                  margin-bottom: 8px;
                }
              }
              &_tmblers {
                padding: 1.5rem;
                border: 1px solid #ababb2;
                border-radius: 5px;
                background: #fff;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;

                .box_list-2 {
                  display: flex;
                  flex-wrap: wrap;
                  padding-bottom: .75rem;

                  .list_item {
                    min-width: 45%;

                    @media (max-width: 1600px) {
                      width: 100%;
                    }
                  }
                }

                .row {
                  display: flex;
                }

                .box_ttl {
                  font-size: 14px;
                  padding-bottom: .5rem;
                }

                .inpt {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  padding: 16px 24px !important;
                  position: relative;
                  align-self: stretch;
                  width: 100%;
                  flex: 0 0 auto;
                  border-radius: 5px;
                  border: 1px solid;
                  border-color: #ababb2;
                  margin-bottom: 0 !important;
                  color: #09090b;
                }
                select.inpt{
                  padding: 4px 24px !important;
                }
              }

              &_left {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-left: 1.5rem;
                }
              }

              &_right {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-right: 1.5rem;
                }
              }

              &_arrow-r {
                position: relative;
                margin-right: 3rem;

                .arrow-r {
                  top: 50%;
                  right: 0;
                  transform: translate(50%, -50%);
                }
              }

              &_arrow-l {
                position: relative;
                margin-left: 3rem;

                .arrow-r {
                  top: 50%;
                  left: 0;
                  transform: translate(-50%, -50%);
                }
              }

              &_prcs {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(19, 180, 151, 0.1);
                padding: 1rem 0;

                > :nth-child(2) {
                  border-left: 2px solid rgba(19, 180, 151, 0.1);
                  border-right: 2px solid rgba(19, 180, 151, 0.1);
                }

                .block {
                  width: calc(33% - .5rem);
                  padding: .25rem 1.5rem;

                  .ttl {
                    font-size: 10px;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.5);
                  }

                  .val {
                    font-size: 1.5rem;

                    &-text {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
          }

          &__bankAcc {
            .ttl {
              svg.img {
                ::v-deep path {
                  fill: #24BAD6;
                }
              }
            }
          }

          &__merchantAcc {
            .ttl {
              svg.img {
                ::v-deep path {
                  fill: #FF056C;
                }
              }
            }
          }
        }
        .footer {
          display: flex;
          justify-content: flex-end;
          padding: 0 !important;
          flex-wrap: wrap;

          .btn {
            &_submit {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: reverse;
              -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              min-height: 3.125rem;
              font-size: 1.25rem;
              cursor: pointer;
              border-radius: 0.3125rem;
              border: 0.125rem solid #3177ff;
            }
          }
        }
        .d-flex{
          display: flex;
        }
        .w-100{
          width: 100%;
          flex: 0 0 100%;
        }
        .total-price{
          &__wrap{
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
          }
          &__title{
            color: #09090b;
            font-size: 20px;
            margin: 0 0;
          }
          &__value{
            color: #3177ff;
            font-size: 20px;
            margin: 0 0;
          }
        }
      }
    }
    .row{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 24px;
    }
    .col-other-services{
      width: 40%;
      flex: 0 0 40%;
      display: flex;
      gap: 12px;
    }
    .inpt{
      height: 2.75rem;
      color: #09090B;
      padding: 0.25rem 0.75rem;
      background-color: inherit;
      border: 1px solid #1763fb;
      border-radius: 6px;
      &.flexible{
        width: 70%;
        max-width: 70%;

      }
    }
    .btn{
      border: none;
      border-radius: 3px;
      background: #1763fb;
      color: #fff;
      height: 2.75rem;
      padding: 0.25rem 0.75rem;
      width: 25%;
    }
  }

  .arrow-r {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep svg {
      width: 65%;
      height: 65%;

      path {
        fill: #ffffff;
      }
    }
  }

  // .inpt {
    // &_procCurr {
    //   width: 50%;
    //   margin-left: 45%;
    // }
  // }
}
.position-relative{
  position: relative;
}
.box--blur{
  filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
}
.box_overlay--blur{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 1.5rem;
  border: 1px solid #ababb2;
  border-radius: 5px;
  background: #fff;
  opacity: .75;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 auto;
  h4{
    text-align: center;
  }
}
.box--blur__wrapper{
  flex: 1 1 auto;
  display: flex;
  width: 25%;
}
.form-control {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 24px !important;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 5px;
  border: 1px solid;
  border-color: #ababb2;
  margin-bottom: 0 !important;
}
.invalid-feedback {
    font-size: 10px;
    width: 100%;
    margin-top: .25rem;
    color: #dc3545;
}
</style>